import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { NotificationAction } from "../../store/actions";

import Loader from "../../components/loader/loader";
import Spacer from "../../components/spacer/spacer";

import NotifiMainInbox from "./notifimaininbox";

import images from "../../assets";

const Notifications = () => {
  const dispatch = useDispatch();

  const { notifications, isLoading } = useSelector(
    (state) => state?.notifications
  );

  const now = moment();
  const newNotifications = notifications?.filter((item) =>
    moment(item?.createdAt).isAfter(now.clone().subtract(24, "hours"))
  );
  const earlierNotifications = notifications?.filter((item) =>
    moment(item?.createdAt).isBefore(now.clone().subtract(24, "hours"))
  );

  const handleUpdate = () => {
    // dispatch(NotificationAction.updateNotification())
  };

  return (
    <div className="notification-area w-full">
      <div className="notify-heading w-full">
        <div className="notifi-heading-text">Notifications</div>
        <div className="notifi-sub-heading" onClick={handleUpdate}>
          Mark all as read
        </div>
      </div>
      <div className="notifi-separator" />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="notifi-content-area">
          {newNotifications?.length > 0 && (
            <>
              <div className="notifi-content-title">New</div>
              {newNotifications.map((item, index) => (
                <NotifiMainInbox
                  title={item?.title}
                  subText={item?.description}
                  time={moment(item?.createdAt).fromNow()}
                  pinkColor={item?.status === "unread" ? "new" : null}
                  className={
                    item?.status === "unread" ? "notifi-unread" : "notifi-read"
                  }
                  route={item?.actionRoute}
                  key={index}
                >
                  <img
                    className="notifi-profile-img"
                    src={item?.image ?? images.notificationIcon}
                    alt=""
                  />
                </NotifiMainInbox>
              ))}
            </>
          )}

          {earlierNotifications?.length > 0 && (
            <>
              <div className="notifi-content-title">Earlier</div>
              {earlierNotifications.map((item) => (
                <NotifiMainInbox
                  title={item?.title}
                  subText={item?.description}
                  time={moment(item?.createdAt).fromNow()}
                  pinkColor={item?.status === "unread" ? "new" : null}
                  className={
                    item?.status === "unread" ? "notifi-unread" : "notifi-read"
                  }
                  route={item?.actionRoute}
                >
                  <img
                    className="notifi-profile-img"
                    src={item?.image ?? images.notificationIcon}
                    alt=""
                  />
                </NotifiMainInbox>
              ))}
            </>
          )}

          <Spacer height="10px" />
        </div>
      )}
    </div>
  );
};

export default Notifications;
