import Axios from "axios";

import { AuthAction } from "../../store/actions";

import { store } from "../../store";
import variables from "../constants";

const { BASE_URL_SEARCH } = variables;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  ({ response }) => {
    if (response.status === 401) {
      store?.dispatch(AuthAction.Logout());
    }
    return response;
  }
);
Axios.interceptors.request.use(
  function (config) {
    const token = store.getState().auth.user.AccessToken;
    const LSToken = localStorage.getItem("authUser");
    const deviceId = localStorage.getItem("config_state_hash");
    if (token) {
      config.headers = {
        ...config?.headers,
        authorization: token,
        requestedFrom: "web",
      };
    } else if (LSToken) {
      config.headers = {
        ...config?.headers,
        authorization: JSON.parse(localStorage.getItem("authUser"))
          ?.AccessToken,
        requestedFrom: "web",
      };
    }
    if (deviceId) {
      config.headers = {
        ...config?.headers,
        config_state_hash: JSON.parse(deviceId),
        requestedFrom: "web",
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default class SearchApiCaller {
  static Get = (endPoint = "", body = {}, headers = {}) => {
    return Axios.get(`${BASE_URL_SEARCH}${endPoint}`, body, {
      headers,
    })

      .then((res) => res)
      .catch((err) => err.response);
  };

  static Post = (endPoint = "", body = {}, headers = {}) => {
    return Axios.post(`${BASE_URL_SEARCH}${endPoint}`, body, {
      headers,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };
  static Put = (endPoint = "", body = {}, headers = {}) => {
    return Axios.put(`${BASE_URL_SEARCH}${endPoint}`, body, {
      headers,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };
  static Delete = (endPoint = "", headers = {}) => {
    return Axios.delete(`${BASE_URL_SEARCH}${endPoint}`, {
      headers,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };
}
