import React from "react";
import { useSelector } from "react-redux";

import {
  religiousHistory,
  beard,
  readQuran,
  religiousSect,
  dailyPrayer,
  religiousCommitment,
  polygamy,
  hijab,
} from "../../utils/arrayitems";

import EditProfileBoxLabel from "../../components/label/editprofileboxlabel";
import Spacer from "../../components/spacer/spacer";
import EditProfileMultipleSelect from "../../components/dropdown/editprofilemultipleselection";

import "./styles.css";

export default function ReligiousStatusFrom(props) {
  const gender = useSelector((state) => state.profile?.profile?.gender);

  return (
    <div className="adv-search-basic-info-area">
      <div
        style={{
          background: `radial-gradient(120% 110% at 80% 160%, #001528 74%, #073AFF00 92%),radial-gradient(150% 80% at 120% 115%, #02305E 70%, #073AFF00 88%),radial-gradient(140% 140% at 110% 140%, #5D4D8C 76%, #5E519200 92%, #073AFF00 100%),radial-gradient(75% 75% at 50% 50%, #362955 100%, #073AFF00 100%)`,
          backgroundSize: "100%",
          backgroundPosition: "center",
        }}
        className="adv-search-basic-info"
      >
        <h2 className="adv-search-info-title">Religious Status</h2>
      </div>
      <div className="adv-search-from-content">
        <div className="adv-search-from-left">
          {/* Religious History */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Religious History" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Religious History"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...religiousHistory,
            ]}
            onChange={(e) =>
              props?.setState({ ...props?.state, religiousHistory: e })
            }
            value={props?.state.religiousHistory || undefined}
          />
          <Spacer height="8px" />
          {/* Beard/hijab */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel
              label={gender?.toLowerCase() === "female" ? "Beard" : "Hijab"}
            />
          </div>
          <Spacer height="8px" />
          {gender?.toLowerCase() === "female" ? (
            <EditProfileMultipleSelect
              placeholder="Beard"
              data={[
                {
                  label: "Doesn't matter",
                  value: "",
                },
                ...beard,
              ]}
              onChange={(e) => props?.setState({ ...props?.state, beard: e })}
              value={props?.state.beard || undefined}
            />
          ) : (
            <EditProfileMultipleSelect
              placeholder="Hijab"
              data={[
                {
                  label: "Doesn't matter",
                  value: "",
                },
                ...hijab,
              ]}
              onChange={(e) => props?.setState({ ...props?.state, hijab: e })}
              value={props?.state.hijab || undefined}
            />
          )}

          <Spacer height="8px" />
          {/* Qur’an Reading */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Qur’an Reading" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Qur’an Reading"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...readQuran,
            ]}
            onChange={(e) => props?.setState({ ...props?.state, readQuran: e })}
            value={props?.state.readQuran || undefined}
          />
        </div>
        {/* Right From Area */}
        <div className="adv-search-from-right">
          {/* Sect */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Sect" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Sect"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...religiousSect,
            ]}
            onChange={(e) =>
              props?.setState({ ...props?.state, religiousSect: e })
            }
            value={props?.state.religiousSect || undefined}
          />

          <Spacer height="8px" />

          {/* Daily Prayer */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Daily Prayer" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Daily Prayer"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...dailyPrayer,
            ]}
            onChange={(e) =>
              props?.setState({ ...props?.state, dailyPrayer: e })
            }
            value={props?.state.dailyPrayer || undefined}
          />
          <Spacer height="8px" />

          {/* Religious Commitment Level */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Religious Commitment Level" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Religious Commitment Level"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...religiousCommitment,
            ]}
            onChange={(e) =>
              props?.setState({ ...props?.state, religiousCommitment: e })
            }
            value={props?.state.religiousCommitment || undefined}
          />
          <Spacer height="8px" />

          {/* Polygamy */}
          <div className="edit-profile-label-container">
            <EditProfileBoxLabel label="Polygamy" />
          </div>
          <Spacer height="8px" />
          <EditProfileMultipleSelect
            placeholder="Polygamy"
            data={[
              {
                label: "Doesn't matter",
                value: "",
              },
              ...polygamy,
            ]}
            onChange={(e) => props?.setState({ ...props?.state, polygamy: e })}
            value={props?.state.polygamy || undefined}
          />
          <Spacer height="8px" />
        </div>
      </div>
    </div>
  );
}
