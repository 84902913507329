import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { SubscriptionAction } from "../../store/actions";

import MembershipList from "../../components/membershipplan/membershiplist";
import { PrimaryButton } from "../../components/button";
import Loader from "../../components/loader/loader";

import images from "../../assets";
import "./styles.css";

export default function MembershipPriceTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile?.profile);
  const planData = useSelector((state) => state.subscription?.paymentPlans);
  const planDataLoading = useSelector(
    (state) => state.subscription?.isPaymentPlanLoading
  );

  const [silverData, setSilverData] = useState();
  const [goldData, setGoldData] = useState();
  const [platinumData, setPlatinumData] = useState();

  useEffect(() => {
    if (!planData) {
      dispatch(SubscriptionAction?.getPaymentPlan());
      planData?.map((plan) => {
        if (plan?.name === "silver") {
          setSilverData(plan);
        } else if (plan?.name === "gold") {
          setGoldData(plan);
        } else if (plan?.name === "platinum") {
          setPlatinumData(plan);
        }
      });
    } else {
      planData?.map((plan) => {
        if (plan?.name === "silver") {
          setSilverData(plan);
        } else if (plan?.name === "gold") {
          setGoldData(plan);
        } else if (plan?.name === "platinum") {
          setPlatinumData(plan);
        }
      });
    }
  }, [planData]);

  const handleMembership = (item) => {
    localStorage.setItem("membershipPlan", JSON.stringify({ ...item }));
    navigate("/order-information");
  };

  const pricingInfo = [
    {
      id: silverData?.id,
      title: "Silver",
      validity: "30 Days",
      save: "Save 10%",
      saveAmount:
        profileData?.country === "Bangladesh" ? `৳1,999.00` : "$19.99",
      amount:
        profileData?.country === "Bangladesh"
          ? `৳${parseFloat(silverData?.bangladesh)?.toLocaleString()}.00`
          : `$${parseFloat(silverData?.amount)?.toLocaleString()}`,
      price: silverData?.amount,
      contactLimit: silverData?.contactView,
      info: [
        `${silverData?.contactView} Contacts View`,
        "Live Chat",
        "Able to Send messages",
        "Premium Badge",
        "All Premium Features",
      ],
    },
    {
      id: goldData?.id,
      title: "Gold",
      validity: "3 Months",
      save: "Save 40%",
      saveAmount:
        profileData?.country === "Bangladesh" ? "৳5,999.00" : "$69.95",
      amount:
        profileData?.country === "Bangladesh"
          ? `৳${parseFloat(goldData?.bangladesh)?.toLocaleString()}.00`
          : `$${parseFloat(goldData?.amount)?.toLocaleString()}`,
      perMonth:
        profileData?.country === "Bangladesh" ? "1300 BDT" : "$13.99 per month",
      price: goldData?.amount,
      contactLimit: goldData?.contactView,
      info: [
        `${goldData?.contactView} Contacts View`,
        "Live Chat",
        "Able to Send messages",
        "Premium Badge",
        "All Premium Features",
      ],
    },
    {
      id: platinumData?.id,
      title: "Platinum",
      validity: "Lifetime Deal",
      save: "Save 60%",
      saveAmount:
        profileData?.country === "Bangladesh" ? "৳21,999.00" : "$269.98",
      amount:
        profileData?.country === "Bangladesh"
          ? `৳${parseFloat(platinumData?.bangladesh)?.toLocaleString()}.00`
          : `$${parseFloat(platinumData?.amount)?.toLocaleString()}`,
      price: platinumData?.amount,
      contactLimit: "Unlimited",
      info: [
        "Unlimited Contacts View",
        "Live Chat",
        "Able to Send messages",
        "Premium Badge",
        "All Premium Features",
      ],
    },
  ];

  const tableData = [
    { title: "Able to send messages", icon: images.closeIcon },
    { title: "Able to read messages", icon: images.checkIcon },
    { title: "Able to reply to received messages", icon: images.checkIcon },
    { title: "Unlimited Live Chat option", icon: images.closeIcon },
    { title: "Advanced Search Option", icon: images.closeIcon },
    { title: "Contact Information", icon: images.closeIcon },
    { title: "Full Profile access", icon: images.checkIcon },
    {
      title:
        "Premium member badge access (stay on top of suggestion / search list)",
      icon: images.closeIcon,
    },
    { title: "No Ads", icon: images.closeIcon },
    {
      title: "Find out matches through NobleMarriage’s matches suggestions",
      icon: images.checkIcon,
    },
    { title: "Send your interest", icon: images.checkIcon },
    { title: "Photo exchange access", icon: images.checkIcon },
    { title: "Add to your shortlist", icon: images.checkIcon },
    { title: "Write personal notes about a profile", icon: images.checkIcon },
    { title: "Trusted badge access", icon: images.checkIcon },
    {
      title: "Translation into different languages option",
      icon: images.checkIcon,
    },
  ];

  return (
    <>
      <div className="membership-payment-subtext">
        <p>
          To start connecting with your match, try our <br />
          Premium Membership Plans!
        </p>
        <span>Choose the plan that’s right for you.</span>
        <div className="membership-payment-pricing-subtext">
          <p>
            <img className="w-[20px] h-[20px]" src={images.checkIcon} alt="" />{" "}
            Simple Tansparent Pricing
          </p>
          <p>
            <img className="w-[20px] h-[20px]" src={images.checkIcon} alt="" />{" "}
            Unlimited Access
          </p>
        </div>
      </div>
      {/* Pricing Table */}
      <div className="w-full flex flex-col gap-[70px] mt-[50px]">
        {planDataLoading ? (
          <Loader />
        ) : (
          <div className="w-full flex flex-col lg:flex-row gap-8 lg:gap-[20px] mx-auto justify-center">
            {pricingInfo?.map((item, index) => (
              <div
                key={index}
                className={
                  item?.title === "Gold"
                    ? "flex w-full flex-col justify-center items-center rounded-md shadow-md relative opacity-100 scale-100"
                    : "flex w-full flex-col justify-center items-center rounded-md shadow-md relative opacity-100 scale-100 lg:opacity-60 lg:scale-75 hover:opacity-100 hover:scale-100 hover:self-start transition-all duration-500"
                }
              >
                {item?.title === "Gold" && (
                  <div
                    style={{
                      backgroundImage: "url(" + images.bestBg + ")",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      position: "absolute",
                      width: "100px",
                      height: "100px",
                      top: "-6px",
                      left: "-10px",
                      zIndex: "10",
                    }}
                  >
                    <h3 className="origin-[50%_47%] -rotate-[48deg] text-white text-[20px] leading-6 font-semibold">
                      BEST
                    </h3>
                  </div>
                )}
                {/* {item?.title !== "Gold" && ( */}
                <div className="w-full h-[72px] bg-basePrimary rounded-md relative mb-10"></div>
                {/* )} */}

                <div className="flex flex-col absolute w-10/12 lg:w-[235px] bg-primary50 text-center gap-[20px] z-0 top-0 mt-4 py-5 rounded-t-md">
                  <h2 className="text-2xl leading-8 font-semibold">
                    {item?.title}
                  </h2>
                  <h2 className="text-2xl leading-8 font-semibold">
                    {item?.validity}
                  </h2>
                </div>
                <div className="w-full flex flex-col bg-white rounded-md">
                  <div className="flex flex-col items-center justify-center mt-[100px]">
                    <div>
                      <p className="text-base leading-6 font-semibold">
                        {item?.save}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm leading-5 font-semibold line-through">
                        {item?.saveAmount}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs leading-4 font-normal">only at</p>
                    </div>
                    <div>
                      <p className="text-4xl leading-10 font-semibold pb-4 pt-2">
                        {item?.amount}
                      </p>
                    </div>
                    <div className="w-full lg:w-[235px] flex flex-col gap-2.5 items-start px-8 lg:px-0">
                      {item?.info?.map((item2, index) => (
                        <p
                          className="flex flex-row gap-2 text-base text-center leading-6 font-normal"
                          key={index}
                        >
                          <img
                            className="w-[20px] h-[20px]"
                            src={images.checkIcon}
                            alt=""
                          />
                          {item2}
                        </p>
                      ))}
                    </div>

                    <div
                      className="w-full flex mt-8 justify-center items-center pb-4 px-5"
                      onClick={() => handleMembership(item)}
                    >
                      <PrimaryButton
                        style={{ fontSize: "16px", fontWeight: 500 }}
                        text={"Continue"}
                        iconalign="right"
                        icon={images.arrowIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col w-full bg-[#F0F4FC] items-center rounded-t-[30px] rounded-b-[10px] shadow-[0_0_10px_3px_rgba(0,0,0,0.3)]">
          <div className="text-xl leading-7 text-center lg:text-3xl lg:leading-9 font-semibold pt-8">
            <h2>List of Membership Benefits</h2>
          </div>
          <div className="flex flex-col w-full px-1 lg:px-[51px] py-8">
            <div className="flex flex-col bg-white justify-between border-solid border border-[#222222] rounded-md">
              <div className="flex flex-row justify-between">
                <div className="w-[232px] md:w-full lg:w-full border-solid border-b border-r border-[#E5E7EB] p-2 px-2 md:px-4 lg:px-6">
                  <h3 className="text-base leading-6 font-semibold">
                    Features
                  </h3>
                </div>
                <div className="w-[200px] flex flex-row justify-center gap-8 border-solid border-b border-r border-[#E5E7EB] px-2 md:px-4 lg:px-6 py-[8px]">
                  <h3 className="text-center text-base leading-6 font-semibold">
                    Basic
                  </h3>
                </div>
                <div className="w-[200px] flex flex-row justify-center gap-8 border-solid border-b border-[#E5E7EB] px-2 md:px-4 lg:px-6 py-[8px]">
                  <h3 className="text-center text-base leading-6 font-semibold">
                    Premium
                  </h3>
                </div>
              </div>

              {tableData?.map((item, index) => (
                <MembershipList
                  key={index}
                  headingtext={item?.title}
                  icon1={item?.icon}
                  icon2={images.checkIcon}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
